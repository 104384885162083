import React from 'react';
import './App.css';

import LandingPage from './pages/landingPage/Index';
// import TermsAndConditions from './pages/termsAndConditions/Index';
// import CancellationAndRefund from './pages/cancellationAndRefund/Index';
// import ContactUs from './pages/contactUs/Index';
// import PrivacyAndPolicy from './pages/privacyAndPolicy/Index';
import PrivacyPage from './pages/policy/Privacy';
import { HashRouter, Route, Routes } from 'react-router-dom';
import TermsAndConditionsPage from './pages/termsAndConditions/TermsAndConditions';
import CancellationAndRefundPage from './pages/cancellationAndRefund/CancellationAndRefund';
import Header from './components/header/Index';
import FooterPage from './components/Footer/footer';
import CupcakePage from './components/cupcake/cupcake'; // Note the renaming here

function App() {
    return (
        <HashRouter>
            <Header />
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
                <Route path="/cancellation-and-refund" element={<CancellationAndRefundPage />} />
                <Route path="/privacy-and-policy" element={<PrivacyPage />} />
                <Route path="/cupcake" element={<CupcakePage />} /> {/* Updated reference */}
            </Routes>
            <FooterPage />
        </HashRouter>
    );
}

export default App;
