import React from 'react';

const cupcakePage: React.FC = () => {
	return (
        <>
            <div className="global-content-pages">
			<div className="container">
				<h1>Cupcake productions  Privacy Policy</h1>
				<p>
					<strong>Effective Date:</strong> November 19, 2024
				</p>
				<h2>1. Scope and Applicability</h2>
				<p>
					This Privacy Policy applies to all users of Cupcake productions platform, mobile applications, and related services. By accessing or using our
					services, you consent to the terms outlined in this Privacy Policy.
				</p>

				<h2>2. Information We Collect</h2>
				<h3>2.1 Personal Data</h3>
				<ul>
					<li>Name, email address, phone number, billing information, and payment details.</li>
					<li>Account credentials, such as username and password.</li>
				</ul>
				<h3>2.2 Content Data</h3>
				<ul>
					<li>Videos, photos, and associated metadata that you upload to our platform.</li>
				</ul>
				<h3>2.3 Usage Data</h3>
				<ul>
					<li>Information such as IP address, browser type, operating system, device details, and interactions with our platform.</li>
				</ul>
				<h3>2.4 Cookies and Tracking Technologies</h3>
				<ul>
					<li>Cookies are used to enhance user experience, remember preferences, and analyze platform performance.</li>
				</ul>
				<h3>2.5 Device and Location Data</h3>
				<ul>
					<li>Device identifiers, hardware model, and operating system information.</li>
					<li>Location data transmitted by your device, which may include GPS or other geolocation information (only collected if enabled).</li>
				</ul>
				<h3>2.6 Communication Data</h3>
				<ul>
					<li>Records of communications with us, including support queries, feedback, and feature requests.</li>
				</ul>

				<h2>3. How We Use Your Information</h2>
				<ul>
					<li>
						<strong>Provide Services:</strong> Operate, maintain, and improve our platform.
					</li>
					<li>
						<strong>Personalize Experiences:</strong> Customize recommendations and enhance user experiences.
					</li>
					<li>
						<strong>Communicate with You:</strong> Send updates, respond to inquiries, and provide support.
					</li>
					<li>
						<strong>Ensure Security:</strong> Detect, prevent, and mitigate fraud or unauthorized activities.
					</li>
					<li>
						<strong>Comply with Laws:</strong> Adhere to legal obligations and enforce our terms of service.
					</li>
					<li>
						<strong>Service Improvements:</strong> Analyze trends, user behavior, and platform performance to improve our offerings.
					</li>
				</ul>

				<h2>4. Data Storage and Processing</h2>
				<p>
					Your data is stored and processed securely on platforms that adhere to strict data protection standards and global compliance frameworks.
				</p>
				<p>
					<strong>Data Localization (India Compliance):</strong> For sensitive personal data related to Indian customers, we ensure that such data is
					stored within India, complying with Indian data localization requirements.
				</p>

				<h2>5. Sharing and Disclosure</h2>
				<ul>
					<li>We do not sell your personal data. However, we may share your information with:</li>
					<li>
						<strong>Service Providers:</strong> Trusted third parties strictly for service-related purposes.
					</li>
					<li>
						<strong>Legal Authorities:</strong> When required by law or to protect our rights.
					</li>
					<li>
						<strong>Business Transfers:</strong> In case of a merger, acquisition, or sale of assets.
					</li>
					<li>
						<strong>Consent for New Purposes:</strong> If we need to use your data for purposes not outlined in this policy, we will seek your
						explicit consent before doing so.
					</li>
				</ul>

				<h2>6. Data Security</h2>
				<ul>
					<li>
						<strong>Encryption:</strong> Data is encrypted in transit and at rest.
					</li>
					<li>
						<strong>Access Controls:</strong> Restricted access to authorized personnel under confidentiality agreements.
					</li>
					<li>
						<strong>Monitoring:</strong> Regular audits, penetration testing, and vulnerability assessments to ensure security.
					</li>
				</ul>

				<h2>7. Data Retention</h2>
				<p>
					Your personal data is retained for as long as necessary to fulfill service requirements or comply with legal obligations. You may request
					the deletion or anonymization of your data at any time by contacting us at <a href="mailto:support@streamefy.com">support@streamefy.com</a>.
				</p>

				<h2>8. Your Rights</h2>
				<h3>8.1 For Users in the EU (GDPR):</h3>
				<ul>
					<li>
						<strong>Right to Access:</strong> Request a copy of your personal data.
					</li>
					<li>
						<strong>Right to Rectification:</strong> Correct any inaccurate information.
					</li>
					<li>
						<strong>Right to Erasure:</strong> Request deletion of your data.
					</li>
					<li>
						<strong>Right to Restrict Processing:</strong> Limit how your data is processed.
					</li>
					<li>
						<strong>Right to Data Portability:</strong> Receive your data in a structured, machine-readable format.
					</li>
					<li>
						<strong>Right to Object:</strong> Withdraw consent or object to data processing.
					</li>
				</ul>
				<h3>8.2 For Users in California (CCPA):</h3>
				<ul>
					<li>
						<strong>Right to Know:</strong> Understand what data we collect and how it is used.
					</li>
					<li>
						<strong>Right to Delete:</strong> Request the deletion of personal data.
					</li>
					<li>
						<strong>Right to Opt-Out:</strong> Prevent the sale of your data (we do not sell data).
					</li>
				</ul>
				<h3>8.3 For Indian Users (IT Act and Emerging Laws):</h3>
				<ul>
					<li>
						<strong>Right to Consent:</strong> Your data is processed only with your consent.
					</li>
					<li>
						<strong>Right to Access:</strong> Understand how your data is collected and processed.
					</li>
					<li>
						<strong>Right to Correction:</strong> Update or correct any inaccuracies.
					</li>
					<li>
						<strong>Right to Grievance Redressal:</strong> File complaints related to data misuse.
					</li>
				</ul>

				<h2>9. Cookies and Tracking Technologies</h2>
				<ul>
					<li>
						<strong>Manage sessions and authentication.</strong>
					</li>
					<li>
						<strong>Improve platform functionality and performance.</strong>
					</li>
					<li>
						<strong>Analyze usage patterns.</strong>
					</li>
				</ul>
				<p>You can control cookie preferences through browser settings.</p>

				<h2>10. International Data Transfers</h2>
				<p>
					Your data may be transferred to servers in other countries. These transfers are conducted in compliance with applicable laws, ensuring your
					data is protected regardless of where it is processed.
				</p>

				<h2>11. Children’s Privacy</h2>
				<p>
					Streamefy is not intended for individuals under 18 years old. We do not knowingly collect personal data from children. Parents or guardians
					may contact us to request data deletion for minors.
				</p>

				<h2>12. Compliance with Indian Laws</h2>
				<p>
					<strong>IT Act, 2000 and IT Rules, 2011:</strong> We adhere to reasonable security practices under Section 43A and 72A to protect your
					sensitive personal data.
				</p>
				<p>
					<strong>Personal Data Protection Bill (Proposed):</strong> We comply with principles of data minimization, purpose limitation, and informed
					consent. Sensitive personal data of Indian customers is stored within India.
				</p>

				<h2>13. Updates to This Privacy Policy</h2>
				<p>
					We may update this policy to reflect changes in our practices or legal requirements. Significant updates will be communicated through email
					or notifications on our platform.
				</p>

				<h2>14. Contact Us</h2>
				<ul>
					<li>
						<strong>Email:</strong> <a href="mailto:support@streamefy.com">support@streamefy.com</a>
					</li>
					<li>
						<strong>Registered Office:</strong> B-203, Euphoria Suncity, Sarjapur Outer Ring Road, Bellandur, Bangalore - 560103, Karnataka
					</li>
					<li>
						<strong>CIN:</strong> U62011KA2024PTC190343
					</li>
				</ul>
			</div>
		</div>
		</>
	);
};
export default cupcakePage;
