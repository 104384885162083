import React from 'react';
import { NavLink } from 'react-router-dom';

const FooterPage: React.FC = () => {
	return (
		<>
			<div className="copy-right-footer">
				<div className="container">
					<div className="copy-right-foote-inner">
						<span>&copy; 2024 by Quable media Tech Private Limited</span>
						<ul>
							<li>
								<NavLink to="/terms-and-conditions">Terms of Use</NavLink>

								<span>|</span>
							</li>
							<li>
								<NavLink to="/cancellation-and-refund">Cancellation and Refund</NavLink>

								<span>|</span>
							</li>
							<li>
								<NavLink to="/privacy-and-policy">Privacy Policy</NavLink>
								<span>|</span>
							</li>
							<li>
								<a href="javascript:void(0)">Cookie Policy</a>
								<span>|</span>
							</li>
							<li>
								<a href="javascript:void(0)"><p>Contact  (+91) 917-724-7894</p></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};
export default FooterPage;
