import { clearConfigCache } from 'prettier';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInputComponent from '../../components/PhoneInputComponent';

const ContactUsPage: React.FC = () => {

const [formData, setFormData] = useState({
		name: '',
		lastname: '',
		phoneNumber: '+91',
		email: '',
		message: '',
	});

    const formik = useFormik({
		initialValues: formData,
		validateOnChange : true,
		validationSchema: Yup.object({
			name: Yup.string()
				.matches(/^[^0-9]*$/, "Only letters are allowed")
  				.min(3, "First Name must be at least 3 characters")
  				.max(50, "First Name cannot be more than 50 characters")
				.required('First Name is required'),
			lastname: Yup.string()
				.matches(/^[^0-9]*$/, "Only letters are allowed")
  				.min(3, "Last Name must be at least 2 characters")
  				.max(50, "Last Name cannot be more than 50 characters")
				.required('Last Name is required'),
			phoneNumber: Yup.string() 
				.matches(/^\d{10,}$/, 'Invalid phone number or incomplete')
				.required('Phone number is required'),
			email: Yup.string().email('Invalid email address').required('Email is required'),
		}),
		onSubmit: async (values, { resetForm }) => {
			try {
				const response = await axios.post('https://api.streamefy.com/api/ContactUs', values);
				console.log('Response:', response.data);

				// Show success message or any other action on success
				Swal.fire({
					icon: 'success',
					title: 'Success!',
					text: 'Contact information submitted successfully.',
					confirmButtonText: 'OK',
				});
				resetForm();
			} catch (error) {
				console.error('Error submitting the form:', error);
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Something went wrong. Please try again later.',
					confirmButtonText: 'OK',
				});
			}
		},
	});
  return (
  <div className="footer-right">
								<h4>Fill the below form to get in touch with us</h4>
								<form onSubmit={formik.handleSubmit}>
									<div className="footer-form">
										<div className="form-group">
											<div className="form-field">
												<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M6 7.5C7.89375 7.5 9.42857 5.9332 9.42857 4C9.42857 2.0668 7.89375 0.5 6 0.5C4.10625 0.5 2.57143 2.0668 2.57143 4C2.57143 5.9332 4.10625 7.5 6 7.5ZM8.4 8.375H7.95268C7.35804 8.65391 6.69643 8.8125 6 8.8125C5.30357 8.8125 4.64464 8.65391 4.04732 8.375H3.6C1.6125 8.375 0 10.0211 0 12.05V12.1106C0 12.8352 0.575893 13.4231 1.28571 13.4231H10.7143C11.4241 13.4231 12 12.8352 12 12.1106V12.05C12 10.0211 10.3875 8.375 8.4 8.375Z"
														fill="#C1C1C1"
													/>
												</svg>
												<input
													type="text"
													name="name"
													placeholder="First Name*"
													value={formik.values.name}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/>
												{formik.touched.name && formik.errors.name ? <div className="error">{formik.errors.name}</div> : null}
											</div>
											<div className="form-field">
												<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M6 7.5C7.89375 7.5 9.42857 5.9332 9.42857 4C9.42857 2.0668 7.89375 0.5 6 0.5C4.10625 0.5 2.57143 2.0668 2.57143 4C2.57143 5.9332 4.10625 7.5 6 7.5ZM8.4 8.375H7.95268C7.35804 8.65391 6.69643 8.8125 6 8.8125C5.30357 8.8125 4.64464 8.65391 4.04732 8.375H3.6C1.6125 8.375 0 10.0211 0 12.05V12.1106C0 12.8352 0.575893 13.4231 1.28571 13.4231H10.7143C11.4241 13.4231 12 12.8352 12 12.1106V12.05C12 10.0211 10.3875 8.375 8.4 8.375Z"
														fill="#C1C1C1"
													/>
												</svg>
												<input
													type="text"
													name="lastname"
													placeholder="Last Name*"
													value={formik.values.lastname}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/>
												{formik.touched.lastname && formik.errors.lastname ? <div className="error">{formik.errors.lastname}</div> : null}
											</div>
										</div>
										<div className="form-group">
											<div className="form-field">
												{/* <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M4.18673 0.895651C3.99123 0.422216 3.47582 0.170226 2.98327 0.30513L0.74899 0.916014C0.307213 1.03819 0 1.44036 0 1.89852C0 8.19571 5.09313 13.3017 11.3745 13.3017C11.8315 13.3017 12.2327 12.9937 12.3545 12.5508L12.9639 10.3109C13.0984 9.8171 12.8471 9.3004 12.3748 9.1044L9.93745 8.08626C9.5236 7.91318 9.04374 8.03281 8.76192 8.38152L7.73618 9.63638C5.94876 8.78878 4.50156 7.33793 3.65609 5.546L4.90779 4.52023C5.25563 4.23515 5.37496 3.75662 5.20231 3.34173L4.18673 0.898196V0.895651Z"
														fill="#C1C1C1"
													/>
												</svg> */}
												{/* <input
													type="tel"
													name="phoneNumber"
													placeholder="Phone Number*"
													value={formik.values.phoneNumber}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/> */}
												<PhoneInputComponent
													value={formik.values.phoneNumber}
													onFocus={(e) => formik.setFieldTouched('phoneNumber', true)}
													onChange={(phone) => formik.setFieldValue('phoneNumber', phone)}
													//onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													inputProps={{ name: 'phoneNumber', required: true, placeholder: 'Phone number ' }}
												/>
												{formik.touched.phoneNumber && formik.errors.phoneNumber ? <div className="error">{formik.errors.phoneNumber}</div> : null}
											</div>
											<div className="form-field">
												<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M13 11.7811C13 12.454 12.4541 12.9999 11.7812 12.9999H1.21875C0.545898 12.9999 4.629e-08 12.454 4.629e-08 11.7811V5.09576C-5.09215e-05 4.91082 0.0419874 4.72829 0.122931 4.56201C0.203874 4.39572 0.321599 4.25004 0.467188 4.13599C1.09941 3.64087 1.62246 3.23716 4.63633 1.05103C5.06289 0.741264 5.91094 -0.00775941 6.5 -0.000142219C7.08906 -0.0102985 7.93711 0.741264 8.36367 1.05103C11.3775 3.23716 11.9006 3.64087 12.5328 4.13599C12.6784 4.25004 12.7961 4.39572 12.8771 4.56201C12.958 4.72829 13.0001 4.91082 13 5.09576V11.7811ZM11.3318 6.78931C11.2658 6.69537 11.1363 6.67251 11.0449 6.74107C10.466 7.17017 9.63574 7.77447 8.36367 8.69869C7.93711 9.01099 7.08906 9.76001 6.5 9.74986C5.91094 9.75747 5.06289 9.01099 4.63633 8.69869C3.36426 7.77447 2.53652 7.17017 1.95508 6.74107C1.86367 6.67251 1.73164 6.69537 1.66816 6.78931L1.43711 7.12447C1.4069 7.16782 1.39478 7.22124 1.40331 7.27339C1.41185 7.32553 1.44036 7.3723 1.48281 7.40376C2.06426 7.83287 2.89199 8.43716 4.15645 9.3563C4.67188 9.73208 5.59102 10.57 6.5 10.5649C7.40645 10.57 8.32812 9.73208 8.84355 9.3563C10.1105 8.43716 10.9357 7.83287 11.5172 7.40376C11.5596 7.3723 11.5882 7.32553 11.5967 7.27339C11.6052 7.22124 11.5931 7.16782 11.5629 7.12447L11.3318 6.78931Z"
														fill="#A9A9A9"
													/>
												</svg>
												<input
													type="email"
													name="email"
													placeholder="Email address*"
													value={formik.values.email}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/>
												{formik.touched.email && formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
											</div>
										</div>
										<div className="form-group full">
											<div className="form-field">
												<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M0.708496 2.17328C0.708496 1.62752 0.929746 1.10411 1.32357 0.718195C1.7174 0.332282 2.25154 0.115479 2.8085 0.115479H12.6085C13.1655 0.115479 13.6996 0.332282 14.0934 0.718195C14.4872 1.10411 14.7085 1.62752 14.7085 2.17328V9.03263C14.7085 9.57839 14.4872 10.1018 14.0934 10.4877C13.6996 10.8736 13.1655 11.0904 12.6085 11.0904H4.6754C4.52394 11.0904 4.37656 11.1386 4.2554 11.2276L1.8285 13.0111C1.7245 13.0875 1.60083 13.134 1.47136 13.1455C1.34189 13.1569 1.21172 13.1328 1.09545 13.0758C0.979172 13.0189 0.881385 12.9313 0.813041 12.8229C0.744696 12.7146 0.708496 12.5897 0.708496 12.4623V2.17328ZM4.2085 2.17328C4.02284 2.17328 3.8448 2.24555 3.71352 2.37419C3.58225 2.50283 3.5085 2.6773 3.5085 2.85922C3.5085 3.04114 3.58225 3.21561 3.71352 3.34425C3.8448 3.47288 4.02284 3.54515 4.2085 3.54515H11.2085C11.3941 3.54515 11.5722 3.47288 11.7035 3.34425C11.8347 3.21561 11.9085 3.04114 11.9085 2.85922C11.9085 2.6773 11.8347 2.50283 11.7035 2.37419C11.5722 2.24555 11.3941 2.17328 11.2085 2.17328H4.2085ZM4.2085 4.91702C4.02284 4.91702 3.8448 4.98929 3.71352 5.11793C3.58225 5.24657 3.5085 5.42104 3.5085 5.60296C3.5085 5.78488 3.58225 5.95935 3.71352 6.08799C3.8448 6.21662 4.02284 6.28889 4.2085 6.28889H11.2085C11.3941 6.28889 11.5722 6.21662 11.7035 6.08799C11.8347 5.95935 11.9085 5.78488 11.9085 5.60296C11.9085 5.42104 11.8347 5.24657 11.7035 5.11793C11.5722 4.98929 11.3941 4.91702 11.2085 4.91702H4.2085ZM4.2085 7.66076C4.02284 7.66076 3.8448 7.73303 3.71352 7.86167C3.58225 7.9903 3.5085 8.16477 3.5085 8.3467C3.5085 8.52862 3.58225 8.70309 3.71352 8.83173C3.8448 8.96036 4.02284 9.03263 4.2085 9.03263H7.0085C7.19415 9.03263 7.3722 8.96036 7.50347 8.83173C7.63475 8.70309 7.7085 8.52862 7.7085 8.3467C7.7085 8.16477 7.63475 7.9903 7.50347 7.86167C7.3722 7.73303 7.19415 7.66076 7.0085 7.66076H4.2085Z"
														fill="#C1C1C1"
													/>
												</svg>
												<textarea
													name="message"
													placeholder="Message here"
													value={formik.values.message}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/>
											</div>
										</div>
										<button type="submit" className="cmn-btn">
											<span>
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
														fill="white"
													></path>
												</svg>
												Submit
											</span>
										</button>
									</div>
								</form>
							</div>
  );
};

export default ContactUsPage;